<template>
  <div id="bg">
    <div class="hui">
      <el-form ref="form" :model="form" label-width="80px" size="small">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="观影设置" name="first">
            <el-form-item label="游客免费观影次数">
              <el-input v-model="form.guest_user_see_number" placeholder="游客免费观影次数"></el-input>
            </el-form-item>
            <el-form-item label="游客免费短视频次数">
              <el-input v-model="form.short_video_times" placeholder="游客免费短视频次数"></el-input>
            </el-form-item>
            <el-form-item label="游客每日下载次数">
              <el-input v-model="form.guest_user_day_download_number" placeholder="游客每日下载次数"></el-input>
            </el-form-item>
            <el-form-item label="VIP每日下载次数">
              <el-input v-model="form.vip_user_day_download_number" placeholder="VIP每日下载次数"></el-input>
            </el-form-item>
            <el-form-item label="SVIP每日下载次数">
              <el-input v-model="form.svip_user_day_download_number" placeholder="SVIP每日下载次数"></el-input>
            </el-form-item>
            <el-form-item label="VIP价格">
              <el-input v-model="form.vip_user_price" placeholder="VIP价格"></el-input>
            </el-form-item>
            <el-form-item label="SVIP价格">
              <el-input v-model="form.svip_user_price" placeholder="SVIP价格"></el-input>
            </el-form-item>
            <el-form-item label="SSVIP加油包返利">
              <el-input v-model="form.ssvip_add_return_price" placeholder="SSVIP加油包返利"></el-input>
            </el-form-item>
            <el-form-item label="SSVIP加油包差价">
              <el-input v-model="form.svip_up_ssvip_price" type="number" placeholder="SSVIP加油包差价"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="注册设置" name="register">
            <el-form-item label="注册送会员天数">
              <el-input v-model="form.up_formal_vip" placeholder="注册送会员天数"></el-input>
            </el-form-item>
            <el-form-item label="注册送会员开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.up_formal_vip_on"></el-switch>
            </el-form-item>
            <el-form-item label="注册赠送观影次数">
              <el-input v-model="form.view_video_count" placeholder="注册送长视频观影次数"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="推广提现" name="second">
            <el-form-item label="新手福利金额">
              <el-input v-model="form.noob_price" placeholder="新手福利金额"></el-input>
            </el-form-item>
            <el-form-item label="提现手续费(%)">
              <el-input v-model="form.fee_price" placeholder="提现手续费"></el-input>
            </el-form-item>
            <el-form-item label="提现开始时间">
              <el-input v-model="form.take_btime" placeholder="提现开始时间"></el-input>
            </el-form-item>
            <el-form-item label="提现结束时间">
              <el-input v-model="form.take_etime" placeholder="提现结束时间"></el-input>
            </el-form-item>
            <el-form-item label="最低提现额">
              <el-input v-model="form.min_tk_price" placeholder="最低提现额"></el-input>
            </el-form-item>
            <el-form-item label="全民分享提成">
              <el-input v-model="form.share_price" placeholder="分享提成"></el-input>
            </el-form-item>
            <el-form-item label="落地页地址">
              <el-input v-model="form.landing" placeholder="落地页地址"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="联系我们" name="third">
            <el-form-item label="视频合作官方邮箱">
              <el-input v-model="form.system_email" placeholder="视频合作官方邮箱"></el-input>
            </el-form-item>
            <el-form-item label="广告合作官方邮箱">
              <el-input v-model="form.advertising_email" placeholder="广告合作官方邮箱"></el-input>
            </el-form-item>
            <el-form-item label="洽谈合作事宜QQ">
              <el-input v-model="form.system_qq" placeholder="洽谈合作事宜QQ"></el-input>
            </el-form-item>
            <el-form-item label="官方Telegram">
              <el-input v-model="form.system_telegram" placeholder="官方Telegram"></el-input>
            </el-form-item>
            <el-form-item label="客服链接">
              <el-input v-model="form.customer_server_url" placeholder="客服链接"></el-input>
            </el-form-item>
            <el-form-item label="官方群链接">
              <el-input v-model="form.official_group_url" placeholder="官方群链接"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="银商设置" name="ys">
            <el-form-item label="银商总开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.ys_open"></el-switch>
            </el-form-item>
            <el-form-item label="银商VIP开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.ys_open_vip"></el-switch>
            </el-form-item>
            <el-form-item label="银商钱包开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.ys_open_wallet"></el-switch>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="下载设置" name="download">
            <el-form-item label="渠道包域名">
              <el-input placeholder="请输入渠道包域名" v-model="download_config.apk_download_host"></el-input>
            </el-form-item>
            <el-form-item label="iOS按钮1">
              <el-switch active-value="1" inactive-value="0" v-model="download_config.ios_lightweight_open"></el-switch>
              <el-input placeholder="请输入按钮1标题" style="width: 200px" v-model="download_config.ios_lightweight_title"></el-input>
            </el-form-item>
            <el-form-item label="iOS按钮2">
              <el-switch active-value="1" inactive-value="0" v-model="download_config.ios_standard_open"></el-switch>
              <el-input placeholder="请输入按钮2标题" style="width: 200px" v-model="download_config.ios_standard_title"></el-input>
              <el-input placeholder="请输入按钮2下载地址" v-model="download_config.ios_standard_url"></el-input>
            </el-form-item>
            <el-form-item label="商务合作">
              <el-switch active-value="1" inactive-value="0" v-model="download_config.cooperation_open"></el-switch>
              <el-input placeholder="跳转地址" v-model="download_config.cooperation_url"></el-input>
            </el-form-item>
            <el-form-item label="一起聊妹">
              <el-switch active-value="1" inactive-value="0" v-model="download_config.download_chat_open"></el-switch>
              <el-input placeholder="跳转地址" v-model="download_config.download_chat_url"></el-input>
            </el-form-item>
            <el-form-item label="认证美女">
              <el-switch active-value="1" inactive-value="0" v-model="download_config.download_girl_open"></el-switch>
              <el-input placeholder="跳转地址" v-model="download_config.download_girl_url"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="其他设置" name="fourth">
            <el-form-item label="APP介绍">
              <el-input v-model="form.app_comment" placeholder="APP介绍"></el-input>
            </el-form-item>
            <el-form-item label="视频鉴权">
              <el-switch active-value="1" inactive-value="0" v-model="form.is_url_signing"></el-switch>
            </el-form-item>
            <el-form-item label="视频CDN">
              <el-input v-model="form.video_cdn" placeholder="视频CDN"></el-input>
            </el-form-item>
            <el-form-item label="CDN地址">
              <el-input v-model="form.pic_url" placeholder="CDN地址"></el-input>
            </el-form-item>
            <el-form-item label="OSS地址">
              <el-input v-model="form.oss_url" placeholder="OSS地址"></el-input>
            </el-form-item>
            <el-form-item label="视频上传秘钥">
              <el-input v-model="form.video_token" placeholder="视频上传秘钥"></el-input>
            </el-form-item>
            <el-form-item label="下载站统计去重">
              <el-switch active-value="1" inactive-value="0" v-model="form.count_rep"></el-switch>
            </el-form-item>
          </el-tab-pane>
<!--          <el-tab-pane label="视频线路" name="videoLine">-->
<!--            <el-row>-->
<!--              <el-col :span="4">线路</el-col>-->
<!--              <el-col :span="16">地址 <el-button @click="addLine">添加</el-button></el-col>-->
<!--            </el-row>-->
<!--            <el-row v-for="(item,index) in video_lines" :key="item">-->
<!--              <el-col :span="4"><el-input v-model="item.title" placeholder="请输入标题"></el-input></el-col>-->
<!--              <el-col :span="16"><el-input v-model="item.url" placeholder="请输入地址"></el-input><icon @click="deleteLine(index)" class="el-icon-delete"></icon></el-col>-->
<!--            </el-row>-->
<!--          </el-tab-pane>-->
        </el-tabs>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="medium">保存设置</el-button>
        </el-form-item>
      </el-form>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/config/httpClient";
// import fuwenben from "@/components/wangEditor";
export default {
  name: "systemParameter",
  components: {
    // fuwenben,
  },
  data() {
    return {
      form: {
        limit_tip:"",
      },
      download_config:{
        apk_download_host:"",
        ios_standard_open:0,
        ios_standard_url:"",
        ios_standard_title:"",
        ios_lightweight_open:0,
        ios_lightweight_title:"",
        cooperation_url:"",
        cooperation_open:0,
        download_girl_url:"",
        download_girl_open:0,
        download_chat_url:"",
        download_chat_open:0,
      },
      video_lines:[],
      isClear: false,
      detail: "",
      activeName:"first"
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    deleteLine(index){
      if (this.video_lines.length <= 1){
        this.$message({ message: "视频线路至少一条", type: "error" });
        return;
      }
      this.video_lines.splice(index,1)
      // 删除线路
    },
    addLine(){
      this.video_lines.push({title:"",url:""})
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //获取列表
    getList() {
      httpClient("systemSetting").post({}).then((res) => {
          if (res.code == 0) {
            // this.form = res.data;
            for (const key in res.data) {
              this.form[key] = res.data[key];
            }
            if (this.form["download_config"] !== undefined && this.form["download_config"] !== ""){
              this.download_config = JSON.parse(this.form["download_config"])
            }
            // if(this.form.video_lines){
            //   this.video_lines = JSON.parse(this.form.video_lines)
            // }
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      // if (this.video_lines.length == 0){
      //   this.$message({ message: "请添加视频线路", type: "error" });
      //   return
      // }
      // for(const line of this.video_lines){
      //   if (line['title']===""){
      //     this.$message({ message: "请输入线路标题", type: "error" });
      //     return
      //   }
      //   if (line['url']===""){
      //     this.$message({ message: "请输入线路", type: "error" });
      //     return;
      //   }
      //   if(line['url'].indexOf("http://") != 0 && line['url'].indexOf("https://") != 0){
      //     this.$message({ message: "线路必须是http/https开头", type: "error" });
      //     return;
      //   }
      // }
      this.$common.showLoading("保存中...")
      this.form["download_config"] = JSON.stringify(this.download_config)
      // this.form.video_lines = JSON.stringify(this.video_lines)
      httpClient("updateSystemSetting").post(this.form).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
      })
    },
  },
};
</script>

<style scoped>
.hui {
  padding: 24px;
  background: #f7f8fa;
  height: calc(100% - 48px);
  overflow-x: auto;
}
.hui::-webkit-scrollbar {
  display: none;
}
.hui >>> .el-form-item__label {
  width: 150px !important;
}
.hui >>> .el-input {
  width: 500px;
}

.hui >>> .key .el-input {
  width: 500px;
}
.hui >>> .el-form-item__content {
  margin-left: 30px !important;
}
.hui span {
  color: #606266;
  margin-left: 10px;
}
</style>
